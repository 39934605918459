html {
	/*font-size: 62.5%; */
	font-size: 10px;
}

@media screen and (max-width: 1024px) {
	html {
		font-size: 55%; } }

@media screen and (max-width: 940px) {
	html {
		font-size: 50%; } }

@media screen and (max-width: 768px) {
	html {
		font-size: 45%; } }

[type=radio] {
	vertical-align: middle;
}

a {
	color: inherit;
	text-decoration: none;
}

a:hover {
	color: inherit;
	text-decoration: inherit;
}

.svg-icon {
	display: inline;
}

ul {
	padding-left: 0;
}

ol {
	padding-left: 2rem;
}

/*
utilidades
*/

.p-relat {
	position: relative;
}

.noscroll {
	overflow: hidden;
}

.nopadding {
    padding: 0;
}

.pad-row {
	padding-left: 15px;
	padding-right: 15px;
}

/* padding reponsivo para os container */
.resp-pad {
	padding: 0;
}

.hidden {
	display: none;
}

/*MARGIN*/
.mtop10 {
	margin-top: 1rem !important;
}
.mtop20 {
	margin-top: 2rem !important;
}
.mtop25 {
	margin-top: 2.5rem !important;
}
.mtop30 {
	margin-top: 3rem !important;
}
.mtop35 {
	margin-top: 3.5rem !important;
}
.mtop40 {
	margin-top: 4rem !important;
}
.mtop50 {
	margin-top: 5rem !important;
}
.mtop70 {
	margin-top: 7rem !important;
}
.mtop100 {
	margin-top: 10rem !important;
}

.mbot5 {
	margin-bottom: 0.5rem !important;
}
.mbot10 {
	margin-bottom: 1rem !important;
}
.mbot15 {
	margin-bottom: 1.5rem !important;
}
.mbot20 {
	margin-bottom: 2rem !important;
}
.mbot25 {
	margin-bottom: 2.5rem !important;
}
.mbot30 {
	margin-bottom: 3rem !important;
}
.mbot35 {
	margin-bottom: 3.5rem !important;
}
.mbot40 {
	margin-bottom: 4rem !important;
}
.mbot50 {
	margin-bottom: 5rem !important;
}
.mbot70 {
	margin-bottom: 7rem !important;
}
.mbot100 {
	margin-bottom: 10rem !important;
}
/*MARGIN*/

/*PADDING*/
.pdtop20 {
	padding-top: 2rem !important;
}
.pdtop30 {
	padding-top: 3rem !important;
}
.pdtop40 {
	padding-top: 4rem !important;
}
.pdtop50 {
	padding-top: 5rem !important;
}
.pdtop70 {
	padding-top: 7rem !important;
}
.pdbot10 {
	padding-bottom: 1rem !important;
}
.pdbot20 {
	padding-bottom: 2rem !important;
}
.pdbot30 {
	padding-bottom: 3rem !important;
}
.pdbot40 {
	padding-bottom: 4rem !important;
}
.pdbot50 {
	padding-bottom: 5rem !important;
}
.pdbot70 {
	padding-bottom: 7rem !important;
}
/*PADDING*/

.upper {
	text-transform: uppercase !important;
}

.bold {
	font-weight: bold !important;
}

.maxw100 {
	max-width: 100%;
	margin: 0;
}
/*
utilidades
*/

@media (max-width: 576px) {
	.container {
	    width: 100%;
	}
}
@media (min-width: 768px) {
	.container {
	    width: 100%;
	}
}
@media (min-width: 992px) {
	.container {
	    width: 100%;
	}
}
@media (min-width: 1200px) {
	.container {
		width: 1200px;
	}
}

/* MAKES MENU SHOW ON SCROLL UP AND HIDE ON SCROLL DOWN */
@media screen and (max-width: 992px) {
	body {
		padding-top: 91px;
	}
	
	.header {
		/* background-color: #fff; */
		top: 0;
		position: fixed;
		width: 100%;
		transition: top 0.2s ease-in-out;
	}
	
	/* we'll add this class using javascript */
	.nav-up {
		top: -91px;  
		/* same as header height. use variables in LESS/SASS */
	}
}
/* END MAKES MENU SHOW ON SCROLL UP AND HIDE ON SCROLL DOWN */

/* SLICK NAVIGATORS POSITION */
.slick-initialized > i[class*="fa-angle"] {
	color: rgba(255, 255, 255, 0.71);
	font-size: 48px;
}

.slick-initialized > i {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1;
}

.slick-initialized > i[class*="fa-"]:hover {
	cursor: pointer;
}

.slick-initialized > i:first-of-type {
	left: 0;
}

.slick-initialized > i:last-of-type {
	right: 0;
}

/* SLICK NAVIGATORS POSITION */

/* MEDIAS */
@media screen and (max-width: 575px) {
	.resp-pad {
		padding-left: 15px;
		padding-right: 15px;
	}
}
/* END MEDIAS */
